import { Stack, Text } from '../../../../../components'
import { AddressVerificationDocs, KycDocuments } from '../../styles/kyc.styles'
import { Color } from '../../../../../assets/theme'
import { IStateProps } from '../../type'

export const KYCDocuments = ({ state }: { state: IStateProps }) => {
  return (
    <KycDocuments>
      <AddressVerificationDocs>
        <Stack direction="row" gap=".5rem" padding=".5rem 0 2rem">
          <Text weight="600" lineHeight="17px" color={Color.alerzoBlack}>
            Address Verification
          </Text>
          <Text weight="600" lineHeight="17px" color={Color.alerzoBlack}>
            <span className="lightFont">{state.documents[5].label}</span>
          </Text>
        </Stack>
        <Stack width="90%">
          <img
            width="100%"
            height="190px"
            alt="rear img"
            src={state.documents[5].value}
          />
        </Stack>
      </AddressVerificationDocs>
    </KycDocuments>
  )
}
