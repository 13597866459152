import React, { useState, useEffect, Dispatch, SetStateAction } from 'react'
import Modal from '../../../../components/modal'
import { Button, Form } from '../../../../components'
import { TextArea } from '../../../../components/modal/styles/modal.styles'

type RejectKYCModalProps = {
  showModal: boolean
  setShowModal: Dispatch<SetStateAction<boolean>>
  onSubmit: (reason: string, comments: string) => void
}

const reasons = [
  { value: '', label: 'Select a reason', disabled: true },
  { value: 'Invalid document', label: 'Invalid document' },
  { value: 'Poor image quality', label: 'Poor image quality' },
  { value: 'Expired document', label: 'Expired document' },
  { value: 'Incomplete information', label: 'Incomplete information' },
  { value: 'Suspicious document', label: 'Suspicious document' },
  { value: 'Other', label: 'Other' },
]

const RejectKYCModal: React.FC<RejectKYCModalProps> = ({
  showModal,
  setShowModal,
  onSubmit,
}) => {
  const [reason, setReason] = useState('')
  const [comments, setComments] = useState('')
  const [isFormValid, setIsFormValid] = useState(false)

  useEffect(() => {
    // Check form validity whenever reason or comments change
    setIsFormValid(reason !== '' && comments.trim() !== '')
  }, [reason, comments])

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault()
    if (isFormValid) {
      onSubmit(reason, comments)
      setShowModal(false)
      // Reset form
      setReason('')
      setComments('')
    }
  }

  return (
    <Modal
      modalWidth="40%"
      showModal={showModal}
      setShowModal={setShowModal}
      title="Reject KYC Document"
    >
      <Form onSubmit={handleSubmit}>
        <Form.Control pb={'1rem'}>
          <Form.Label>Reason for Rejection</Form.Label>
          <Form.Select
            options={reasons}
            value={reason}
            onChange={(e) => setReason(e.target.value)}
            required
          />
        </Form.Control>
        <Form.Control pb={'1rem'}>
          <Form.Label>Comments</Form.Label>
          <TextArea
            textAreaHeight="100px"
            value={comments}
            onChange={(e) => setComments(e.target.value)}
            required
          />
        </Form.Control>
        <Form.Footer>
          <Button onClick={handleSubmit} width="100%" disabled={!isFormValid}>
            Submit
          </Button>
        </Form.Footer>
      </Form>
    </Modal>
  )
}

export default RejectKYCModal
