import { useMutation } from 'react-query'

import { postRequest } from '../../../../utils/apiRequest'
import { errorMessage } from '../../../../utils/message'
import toast from 'react-hot-toast'

type ResetPasswordProps = {
  adminId: string
  reason?: string
  action: 'activate' | 'deactivate'
}
const useActivateDeactivate = ({
  adminId,
  action,
  reason,
}: ResetPasswordProps) => {
  const onDeactivateActivate = () => {
    return postRequest({
      pathUrl: `/members/${adminId}/${action}`,
      payload: { reason },
      methodType: 'patch',
    })
  }

  return useMutation(onDeactivateActivate, {
    onError: (error: Error) => {
      toast.error(`${errorMessage(error)}`)
    },
    onSuccess: (error: Error) => {
      toast.success(`Successfully ${action}ed user`)
    },
  })
}

export default useActivateDeactivate
