// import { Suspense } from "react";
import { Routes, Route, useNavigate } from 'react-router-dom'
import ScrollToTop from 'src/utils/ScrollTo'
import Login from 'src/pages/login'
import Transactions from 'src/pages/dashboard/transactions'
import Dashboard from 'src/pages/dashboard'
import Overview from 'src/pages/dashboard/overview'
import { Path } from 'src/constants/route-path'
import Users from 'src/pages/dashboard/employees'
import { IsUserRedirect, ProtectedRoutes } from 'src/configs/private-route'

import TransactionDetails from 'src/pages/dashboard/transactions/transaction-details'
import { useAppContext } from 'src/context'
import { getStorageItem } from 'src/utils/session-storage'
import Verification from 'src/pages/verification'
import Terminals from 'src/pages/dashboard/terminals'
import TerminalDetails from 'src/pages/dashboard/terminals/details'
import Businesses from 'src/pages/dashboard/businessess'
import BusinessDetails from 'src/pages/dashboard/businessess/business-detail'
import DigitalBank from 'src/pages/dashboard/digitalBank'
import DigitalBankDetails from 'src/pages/dashboard/digitalBank/details'
import Products from 'src/pages/dashboard/products'
import ProductDetails from 'src/pages/dashboard/products/details'
import RegisterInvitation from 'src/pages/invitation'
import NotFound from 'src/pages/404'
import Audit from 'src/pages/dashboard/audit'
import { ErrorBoundary } from 'react-error-boundary'
import { ErrorFallback } from 'src/components/error-boundary'
import TerminalRequestDetails from 'src/container/dashboard/terminals/details/request-detail'
import KYC from 'src/pages/dashboard/KYC'
import KYCDetails from 'src/pages/dashboard/KYC/details'
import BusinessProductDetails from 'src/pages/dashboard/businessess/business-detail/business-product-details'
import Biller from 'src/pages/dashboard/biller'
import BillerDetail from 'src/pages/dashboard/biller/biller-detail'
import PasswordReset from 'src/pages/password-reset'

function App() {
  const navigate = useNavigate()
  const { state } = useAppContext()
  const user = getStorageItem('user') || state.user

  let routes = (
    <>
      <ScrollToTop />
      <ErrorBoundary
        FallbackComponent={ErrorFallback}
        onReset={() => {
          // reset the state of your app so the error doesn't happen again
          navigate('dashboard', { replace: true })
        }}
      >
        <Routes>
          <Route element={<IsUserRedirect user={user} />}>
            <Route path={Path.LOGIN} element={<Login />} />
          </Route>
          <Route element={<IsUserRedirect user={user} />}>
            <Route path={Path.VERIFY_OTP} element={<Verification />} />
          </Route>
          <Route
            path={Path.INVITATIONREGISTER}
            element={<RegisterInvitation />}
          />
          <Route path={Path.PASSWORD_RESET} element={<PasswordReset />} />

          <Route element={<ProtectedRoutes user={user} />}>
            <Route path={Path.DASHBOARD} element={<Dashboard />}>
              <Route index element={<Overview />} />
              <Route path={Path.USERS} element={<Users />} />
              <Route path={Path.USERSDETAILS} element={<Users />} />
              <Route path={Path.TRANSACTION} element={<Transactions />} />
              <Route
                path={Path.TRANSACTIONDETAIL}
                element={<TransactionDetails />}
              />
              <Route path={Path.TERMINALS} element={<Terminals />} />
              <Route path={Path.TERMINALDETAIL} element={<TerminalDetails />} />
              <Route
                path={Path.TERMINALREQUESTDETAIL}
                element={<TerminalRequestDetails />}
              />
              <Route path={Path.BUSINESSES} element={<Businesses />} />
              <Route path={Path.BUSINESSDETAIL} element={<BusinessDetails />} />
              <Route
                path={Path.BUSINESSPRODUCTDETAIL}
                element={<BusinessProductDetails />}
              />
              <Route path={Path.DIGITALBANK} element={<DigitalBank />} />
              <Route
                path={Path.DIGITALBANKDETAIL}
                element={<DigitalBankDetails />}
              />
              <Route path={Path.PRODUCTS} element={<Products />} />
              <Route path={Path.PRODUCTDETAIL} element={<ProductDetails />} />
              <Route path={Path.AUDIT} element={<Audit />} />
              <Route path={Path.KYC} element={<KYC />} />
              <Route path={Path.KYCDETAIL} element={<KYCDetails />} />
              <Route
                path={Path.KYCDETAIL_ADMIN}
                element={<KYCDetails role="admin" />}
              />
              <Route
                path={Path.KYCDETAIL_COMP}
                element={<KYCDetails role="compliance" />}
              />
              <Route path={Path.BILLER} element={<Biller />} />
              <Route path={Path.BILLERDETAIL} element={<BillerDetail />} />
            </Route>
          </Route>

          {/* replace with not found component */}
          <Route path="*" element={<NotFound user={user} />} />
        </Routes>
      </ErrorBoundary>
    </>
  )

  return routes
}

export default App
