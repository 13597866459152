import React from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { transformData } from '../../../helper/table.helper'
import {
  formatDate,
  amountConverter,
  convertSnakeStringToWords,
} from '../../../utils/formatValue'
import { TableButton, TableItemDiv } from './table.style'
import { useAppContext } from '../../../context'
import { Action } from '../../../context/actions'
import { getClassNames } from './table-classnames'

type SelectedDataType = Record<string, any>

interface TableDataProps {
  tableData: SelectedDataType[]
  name: string
  amountIndex?: number
  withSlug?: boolean
  dateFormat?: string
  hideActive?: boolean
  hideDate?: boolean
  setParams?: boolean
  notClickable?: boolean
  routePath?: string
  noSlug?: boolean
  actionBtnLabel?: string
  handleRouthPath?: (item: SelectedDataType) => void
  handleAction?: (item: SelectedDataType) => void
}

const TableData: React.FC<TableDataProps> = ({
  tableData,
  name,
  amountIndex,
  withSlug,
  dateFormat,
  hideActive,
  hideDate,
  setParams,
  notClickable,
  routePath,
  noSlug,
  actionBtnLabel,
  handleRouthPath,
  handleAction,
}) => {
  const { dispatch } = useAppContext()
  const navigate = useNavigate()
  const [searchParams, setQueryParams] = useSearchParams()
  const params = Object.fromEntries(searchParams)

  const handleItemClick = (item: SelectedDataType) => {
    if (handleRouthPath) {
      handleRouthPath(item)
    } else if (notClickable) {
      return
    } else if (setParams) {
      setQueryParams({ ...params }, { state: { detail: item } })
    } else if (routePath) {
      if (name === 'requestsTerrminals') {
        dispatch({ type: Action.SAVETERMINALREQ, payload: item })
      }
      let path
      if (withSlug) {
        path = `/${routePath}/${item.id}/${item.product?.slug}`
      } else if (noSlug) {
        path = `/${routePath}`
      } else {
        const [basePath, existingQuery] = routePath.split('?')
        path = `/${basePath}/${item.id}?${existingQuery}`
      }

      navigate(path, { replace: true, state: item })
    } else {
      const path = withSlug ? `${item.id}/${item.product?.slug}` : `${item.id}`
      navigate(path, { state: { detail: item } })
    }
  }

  const renderTableCell = (
    data: string,
    index: number,
    item: SelectedDataType
  ) => {
    if (index === amountIndex) {
      return `₦${amountConverter(data)}`
    }
    if (!hideDate && index === Object.values(item).length - 1) {
      return formatDate(data, dateFormat || 'lll')
    }
    return data
  }

  return (
    <tbody>
      {tableData?.map((item, index) => {
        const newObj = transformData({ item, name })
        const dataList = newObj && Object.values(newObj)

        return (
          <tr key={index}>
            {dataList?.map((data, i) => (
              <td key={i} id="td-hover">
                <TableItemDiv
                  onClick={() => handleItemClick(item)}
                  className={getClassNames(data, item, i, hideActive)}
                >
                  {renderTableCell(data, i, item)}
                </TableItemDiv>
              </td>
            ))}
            {handleAction && (
              <td>
                <TableButton onClick={() => handleAction(item)}>
                  {actionBtnLabel ?? 'Edit'}
                </TableButton>
              </td>
            )}
          </tr>
        )
      })}
    </tbody>
  )
}

export default TableData
