import { useQuery } from 'react-query'

import { getResource } from '../../../../utils/apiRequest'

const useGetBusinessDirectors = ({
  query,
  id,
  businessId,
  from,
  to,
  count,
  pageNumber,
}: {
  query?: string
  id: string
  businessId?: string
  from?: string
  to?: string
  count?: number
  pageNumber?: number
}) => {
  const getBusinessDirectors = (id: string) => {
    const params = new URLSearchParams()
    if (query) params.append('query', query)
    if (id) params.append('id', id)
    if (businessId) params.append('businessId', businessId)
    if (from) params.append('from', from)
    if (to) params.append('to', to)
    if (count) params.append('count', count?.toString())
    if (pageNumber) params.append('pageNumber', pageNumber?.toString())
    return getResource(`business-directors?${params?.toString()}`)
  }

  return useQuery(['businesses-directors', id], () => getBusinessDirectors(id))
}

export default useGetBusinessDirectors
