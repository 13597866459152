import { InviteSent } from '../../assets/icons'
import DangerWarning from '../../assets/icons/danger-warning'
import Form from '../form'
import { Input } from '../form/styles/form.style'
import Modal from '../modal'
import Stack from '../stack'
import { ConfirmationProps } from './type'

const ConfirmationModal = ({
  showModal,
  handleShow,
  showStatus,
  title,
  setShowStatus,
  handleChange,
  message,
  btnText = 'Change Biller',
  children = null,
}: ConfirmationProps) => {
  return (
    <>
      <Modal
        showModal={showModal}
        setShowModal={() => {
          handleShow(false)
        }}
        titleSize="22px"
        modalWidth="320px"
        title={title}
        contentPadding="0"
        icon={<DangerWarning />}
        subTitleSize={'16'}
        subTitle={message}
        // subTitle={}
        handleSubmit={handleChange}
        cancelBtnText="Cancel"
        buttonText={btnText}
      >
        {children}
      </Modal>
      <Modal
        showModal={showStatus as boolean}
        setShowModal={() => {
          setShowStatus(false)
        }}
        titleSize="22px"
        modalWidth="320px"
        title="Biller Changed"
        subTitleMargin="0"
        contentPadding="0"
        icon={<InviteSent />}
        subTitleSize={'16'}
        subTitle={''}
        handleSubmit={() => {}}
        cancelBtnText="Back To Products"
      />
    </>
  )
}

export default ConfirmationModal
