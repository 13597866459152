import { useState } from 'react'
import { useQuery } from 'react-query'
import {
  FallBack,
  Filter,
  Jumbotron,
  Loader,
  Pagination,
  Table,
  TabsPage,
} from 'src/components'
import { Container } from 'src/components/layout'
import { filterValue } from 'src/data/filter-data'
import { KYCHeaderList } from 'src/data/table-headers'
import { getNewFilterResource } from 'src/utils/apiRequest'
import { errorMessage } from 'src/utils/message'
import { Color } from 'src/assets/theme'
import { useLocation } from 'react-router-dom'

const KYC = () => {
  const [values, setValues] = useState(filterValue)

  const getKYCVerifications = () => {
    return getNewFilterResource('kyc/verifications', values)
  }

  const { isLoading, isError, data, refetch, error, isFetching } = useQuery(
    ['KYC', values],
    getKYCVerifications,
    { keepPreviousData: true }
  )

  const TABS = [
    {
      label: 'Administrator',
      value: 'admin',
      title: '',
    },
    {
      label: 'Compliance',
      value: 'compliance',
      title: '',
    },
  ]
  const location = useLocation()
  const search = location.search
  let queryParam = new URLSearchParams(search).get('status')

  const found = TABS.find((element) => element.value === queryParam)

  const getComplianceKYC = () => {
    return Array.isArray(data?.data)
      ? data.data.filter(
          (element: any) =>
            element.status === 'awaiting_compliance' ||
            element.status === 'verified' ||
            element.status === 'rejected'
        )
      : []
  }
  const getAdminKYC = () => {
    return Array.isArray(data?.data)
      ? data.data.filter(
          (element: any) => element.status !== 'awaiting_compliance'
        )
      : []
  }
  let component
  if (isLoading) {
    component = <Loader />
  } else if (isError) {
    component = (
      <FallBack error refetch={refetch} title={`${errorMessage(error)}`} />
    )
  } else if (data?.data?.length < 1) {
    component = <FallBack title={'You have no KYC yet.'} refetch={refetch} />
  } else {
    component = (
      <>
        <TabsPage.Tabs
          hideStatus={true}
          color={Color.alerzoBlack}
          tabs={TABS}
          currentValue={found?.value || 'admin'}
        />
        <Jumbotron padding={'0'}>
          <Table
            headerbgColor={'transparent'}
            tableName="KYC"
            tableData={
              found?.value === 'compliance' ? getComplianceKYC() : getAdminKYC()
            }
            tableHeaders={KYCHeaderList}
            routePath={`dashboard/kyc?status=${found?.value || 'admin'}`}
            hideDate
          />
        </Jumbotron>
        <Pagination data={data} setPageNumber={setValues} />
      </>
    )
  }
  return (
    <Container isFetching={isFetching} title={'KYC Verification'}>
      <Jumbotron padding={'.5rem 1rem'} direction={'column'}>
        <Filter
          setFilterValues={setValues}
          showFilters={{
            search: {
              placeholder: 'Search',
              type: 'text',
            },
            date: true,
            selects: [
              {
                query: 'status',
                placeholder: 'Status',
                values: [
                  { value: 'verified', label: 'Approved' },
                  { value: 'rejected', label: 'Rejected' },
                  { value: 'processing', label: 'Pending' },
                  {
                    value: 'awaiting_compliance',
                    label: 'Awaiting Compliance',
                  },
                ],
                value: '',
              },
            ],
          }}
        />
        {component}
      </Jumbotron>
    </Container>
  )
}
export default KYC
