import { Fragment, useMemo, useState } from 'react'
import { Color } from '../../../../../assets/theme'
import { Button, Stack, Text } from '../../../../../components'
import { DocFile } from './doc-file'
import SuccessModal from '../../../../../components/success-modal/success-modal'
import RejectKYCModal from '../../components/denied-modal'
import PreviewKYCModal from '../../components/preview-modal'
import { useLocation } from 'react-router-dom'
import { doc } from 'prettier'

const docTypes = {
  documentNumber: 'documentNumber',
  businessCAC: 'businessCAC',
  documentPhotos: 'documentPhotos',
  businessLicense: 'business License',
  businessProofOfAddress: 'businessProofOfAddress',
}

type DocumentsType = Record<string, string>

type Documents = {
  key: string
  label?: string
  docType: string
  status: string
  documentNumber: string | undefined
  file: string | undefined
}

type B2BDocumentsProps = {
  state: {
    documents: DocumentsType[]
  }
  id: string
  role?: string
  isAwaitingCompliance?: boolean
}

type KycDataType = {
  [x: string]: {
    status: 'rejected' | 'verified'
    reason: string
    comments: string
  }
}

const isValidUrl = (url: string) => {
  try {
    new URL(url)
    return true
  } catch {
    return false
  }
}

const B2BDocuments = ({
  state,
  id,
  role,
  isAwaitingCompliance,
}: B2BDocumentsProps) => {
  const [kycData, setKycData] = useState<KycDataType>({})
  const [showSuccess, setShowSuccess] = useState(false)
  const [approvalStatus, setApprovalStatus] = useState<{
    message?: string
    status?: 'approve' | 'declined'
  }>({})
  const [showDenied, setShowDenied] = useState(false)
  const [selectedDocument, setSelectedDocument] = useState<Documents | null>(
    null
  )
  const [openPreview, setOpenPreview] = useState(false)
  const documentNumber = state?.documents?.find(
    (v: DocumentsType) => v?.key === docTypes.documentNumber
  )

  const acceptedDocuments = useMemo(
    () => [
      'selfie',
      'documentPhotos',
      'businessProofOfAddress',
      'businessCAC',
      'memart',
      'statusReport',
    ],
    []
  )
  const documents: Documents[] = useMemo(() => {
    return state.documents
      .filter((doc) => acceptedDocuments.includes(doc.key))
      .map((doc) => ({
        key: doc.key,
        label: doc.label,
        docType: doc.key,
        documentNumber: documentNumber?.value,
        file: doc.value as string,
        status: doc.status,
        isAwaitingCompliance,
        ...doc,
      }))
  }, [
    acceptedDocuments,
    isAwaitingCompliance,
    documentNumber?.value,
    state.documents,
  ])

  const handleAccept = (document: Documents) => {
    if (document.key) {
      setKycData((prevKycData) => ({
        ...prevKycData,
        [document.key]: {
          status: 'verified',
          reason: 'Document is Valid',
          comments: 'Approved',
        },
      }))
      setApprovalStatus({
        message: `You have accepted ${document.label} for KYC upgrade`,
        status: 'approve',
      })
      setShowSuccess(true)
    }
  }

  const handleDecline = (document: Documents) => {
    setSelectedDocument(document)
    setShowDenied(true)
  }

  const handleDeniedSubmit = (reason: string, comments: string) => {
    if (selectedDocument?.key) {
      setKycData((prevKycData) => ({
        ...prevKycData,
        [selectedDocument.key]: {
          status: 'rejected',
          reason: reason,
          comments: comments,
        },
      }))
      setApprovalStatus({
        message: `You have rejected ${selectedDocument?.label} for KYC upgrade`,
        status: 'declined',
      })
    }

    setShowDenied(false)
    setShowSuccess(true)
  }

  const handleRevert = (document: Documents) => {
    setKycData((prevKycData) => {
      const updatedKycData = { ...prevKycData }
      if (document.key) {
        delete updatedKycData[document.key]
      }
      return updatedKycData
    })
  }

  const isDocumentResponded = (document: Documents) => {
    if (document.key && kycData[document.key]) {
      const docStatus = kycData[document.key].status
      return docStatus === 'verified' || docStatus === 'rejected'
    }
    return false
  }

  const location = useLocation()
  const search = location.search
  let queryParam = new URLSearchParams(search).get('status')

  /**
   *  check if the document can be responded to
   * if true, document can be reviewed
   * @param document
   * @returns
   */
  const canResponse = (document?: Documents) => {
    if (queryParam === 'compliance') {
      if (isAwaitingCompliance) {
        if (document) {
          /**
           * document is not ready for compliance.
           * Return the status if document has been responded to
           *
           */
          return (
            document?.status !== 'verified' && document?.status !== 'rejected'
          )
        } else {
          return isAwaitingCompliance
        }
      } else {
        return false
      }
    } else {
      /**
       * document is not ready for admin to review
       * Return the status if document has been responded to
       *
       */
      return document
        ? !(document?.status === 'verified' || document?.status === 'rejected')
        : false
    }
  }

  return (
    <Stack width="auto" border={`1px solid ${Color.alerzoGrayBorder}`}>
      <SuccessModal
        showSuccess={showSuccess}
        setShowSuccess={setShowSuccess}
        btnText="Ok"
        message={approvalStatus?.message || ''}
        isError={approvalStatus?.status === 'declined'}
        title={
          approvalStatus?.status === 'declined'
            ? 'Request Declined'
            : 'Request Approved'
        }
        onClick={() => {
          setShowSuccess(false)
          setApprovalStatus({}) // Reset the success message
        }}
      />
      <RejectKYCModal
        showModal={showDenied}
        setShowModal={setShowDenied}
        onSubmit={handleDeniedSubmit}
      />
      <PreviewKYCModal
        showModal={openPreview}
        setShowModal={setOpenPreview}
        kyc={kycData}
        role={role}
        verificationId={id}
      />
      <Stack marginTop="1rem" borderRadius="10px" width="auto">
        {documents.map((document, index) => (
          <Stack
            borderBottom={`1px solid ${Color.alerzoGrayBorder}`}
            key={index}
          >
            <Stack
              direction="row"
              justifyContent="space-between"
              borderRadius="10px 10px 0 0"
              width="auto"
            >
              <Stack justifyContent="center" padding=" .5rem" width="50%">
                <Text
                  as="p"
                  size="14px"
                  padding="0"
                  color={Color.alerzoGrayishBlue2}
                >
                  <strong>Doc. Type: </strong>
                  {document.docType}
                </Text>
              </Stack>
              <Stack justifyContent="center" padding=".5rem" width="50%">
                {document.documentNumber && (
                  <Text as="p" size="14px" color={Color.alerzoGrayishBlue2}>
                    Doc. Number:
                    <strong> {document.documentNumber}</strong>
                  </Text>
                )}
              </Stack>
            </Stack>
            <Stack
              height={'auto'}
              transition="height 1s ease-in-out"
              overflow="hidden"
            >
              <Stack pt="1rem" alignItems="center" justifyContent="center">
                {document.file ? (
                  <DocFile doc={document.file} docType={document.docType} />
                ) : (
                  <Text as="p" padding="0 0 1rem">
                    Not Applicable
                  </Text>
                )}
              </Stack>
            </Stack>
            <Stack
              display="flex"
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              padding="5px"
              cursor="pointer"
              marginBottom="10px"
              marginTop="10px"
            >
              <div style={{ flex: 0.3 }}>
                <Text as="p" padding="0 0 1rem">
                  {document.label}
                </Text>
              </div>
              {!!document.file && (
                <div
                  style={{ flex: 0.7, display: 'flex', alignItems: 'center' }}
                >
                  {!canResponse(document) ? (
                    <Stack
                      padding="10px"
                      bgColor={
                        document.status === 'verified'
                          ? Color.alerzoGreenBg
                          : Color.alerzoDanger100
                      }
                      borderRadius="10px"
                    >
                      <Text as="p" padding="0">
                        Admin or Compliance has responded to this document and
                        its {document.status}
                      </Text>
                    </Stack>
                  ) : // Admin tab but the document s not ready for admin to review
                  queryParam !== 'compliance' &&
                    document.status !== 'processing' ? (
                    <Stack
                      padding="10px"
                      bgColor={Color.alerzoStatusPending}
                      borderRadius="10px"
                    >
                      <Text as="p" padding="0">
                        This document is not available for you to review as its
                        currently {document.status}
                      </Text>
                    </Stack>
                  ) : !isDocumentResponded(document) ? (
                    <>
                      <Button
                        width="45%"
                        className="btn-sm"
                        onClick={() => handleAccept(document)}
                        variant={Color.alerzoGreenBg}
                        color={Color.alerzoGreen}
                      >
                        Accept
                      </Button>
                      <Button
                        margin="0 0 0 10px"
                        width="45%"
                        onClick={() => handleDecline(document)}
                        variant={Color.alerzoDanger100}
                        color={Color.alerzoDanger}
                      >
                        Decline
                      </Button>
                    </>
                  ) : (
                    <Text as="p" padding="0">
                      You have responded.{' '}
                      <Text
                        as="span"
                        color={Color.alerzoBlue}
                        style={{
                          textDecoration: 'underline',
                          cursor: 'pointer',
                        }}
                        onClick={() => handleRevert(document)}
                      >
                        Click to revert
                      </Text>
                    </Text>
                  )}
                </div>
              )}
            </Stack>
          </Stack>
        ))}
      </Stack>
      <Button margin="0 0 0 10px" onClick={() => setOpenPreview(true)}>
        Preview
      </Button>
    </Stack>
  )
}

export default B2BDocuments
