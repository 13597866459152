import React, { Dispatch, SetStateAction } from 'react'
import Modal from '../../../../components/modal'
import { Button, Stack, Text } from '../../../../components'
import { Color } from '../../../../assets/theme'
import useApproveKycDocument from '../hooks/useApproveKycDocument'
import { useSearchParams } from 'react-router-dom'

type PreviewKYCModalProps = {
  showModal: boolean
  role?: string
  verificationId: string
  setShowModal: Dispatch<SetStateAction<boolean>>
  kyc: {
    [x: string]: {
      status: 'rejected' | 'verified'
      reason: string
      comments: string
    }
  }
}
const PreviewKYCModal: React.FC<PreviewKYCModalProps> = ({
  showModal,
  setShowModal,
  kyc,
  verificationId,
}) => {
  const documentNames: { [key: string]: string } = {
    businessCAC: 'CAC',
    documentPhotos: 'Valid ID',
    businessLicense: 'Operating License',
    businessProofOfAddress: 'Proof of Address',
  }
  const [searchParams] = useSearchParams()

  const pageParam = searchParams.get('status')

  const { mutate, isLoading } = useApproveKycDocument(verificationId, () =>
    setShowModal(false)
  )

  const handleSubmit = () => {
    return mutate({
      ...kyc,
      reviewerUnit:
        typeof pageParam === 'undefined' || pageParam === 'undefined'
          ? 'admin'
          : pageParam,
    })
  }

  return (
    <Modal
      modalWidth="40%"
      showModal={showModal}
      setShowModal={setShowModal}
      title="KYC Document Summary"
    >
      <Stack spacing={20}>
        {Object.entries(kyc).length === 0 && (
          <Text
            style={{ textAlign: 'center' }}
            as="p"
            padding="0"
            color={Color.alerzoGrayTwo}
          >
            No documents to preview
          </Text>
        )}
        {Object.entries(kyc).map(([key, value]) => (
          <Stack
            marginTop="20px"
            padding="0 0 10px 0"
            borderBottom={`1px solid ${Color.alerzoGrayBorder}`}
            key={key}
          >
            <Text color={Color.alerzoGrayTwo} as="p" size="14px" padding="0">
              Document Type
            </Text>
            <Text as="p" size="14px" padding="0">
              <strong> {documentNames[key] || key}</strong>
            </Text>
            <Stack marginTop="10px">
              <Text color={Color.alerzoGrayTwo} as="p" size="14px" padding="0">
                Status
              </Text>
              <Stack
                width="20%"
                borderRadius="10px"
                margin="auto"
                alignItems="center"
                padding="5px"
                bgColor={
                  value.status === 'verified'
                    ? Color.alerzoGreenBg
                    : Color.alerzoDanger100
                }
                marginTop="10px"
              >
                <Text
                  textAlign="center"
                  as="p"
                  size="14px"
                  padding="0"
                  color={
                    value.status === 'verified'
                      ? Color.alerzoGreen
                      : Color.alerzoDanger
                  }
                >
                  <strong>
                    {value.status === 'verified' ? 'Accepted' : 'Rejected'}
                  </strong>
                </Text>
              </Stack>
            </Stack>
            {value.status === 'rejected' && (
              <Stack marginTop="10px">
                <Text
                  color={Color.alerzoGrayTwo}
                  as="p"
                  size="14px"
                  padding="0"
                >
                  Reason
                </Text>
                <Text as="p" size="14px" padding="0">
                  {value.reason}
                </Text>
              </Stack>
            )}
            <Stack marginTop="10px">
              <Text color={Color.alerzoGrayTwo} as="p" size="14px" padding="0">
                Comments
              </Text>
              <Text as="p" size="14px" padding="0">
                {value.comments}
              </Text>
            </Stack>
          </Stack>
        ))}
      </Stack>
      {Object.entries(kyc).length > 0 && (
        <Button loading={isLoading} margin="20px 0" onClick={handleSubmit}>
          Submit
        </Button>
      )}
    </Modal>
  )
}

export default PreviewKYCModal
