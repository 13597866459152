import { amountHelper, formatDate } from '../utils/formatValue'

export const TABS = [
  { label: 'Business Details', value: 'details', title: 'Business Details' },
  { label: 'Transaction History', value: 'transaction', title: '' },
  { label: 'Members', value: 'members', title: '' },
  { label: 'Products', value: 'products', title: '' },
  // { label: 'KYC Documents', value: 'kyc', title: '' },
  { label: 'Terminals', value: 'terminals', title: '' },
  { label: 'Bank Accounts', value: 'accounts', title: '' },
]

export const DETAILSTABLE = [
  { label: 'Business ID', value: 'id', columnWidth: 'small' },
  { label: 'Business Name', value: 'name', columnWidth: 'small' },
  { label: 'Phone Number', value: 'phone_number', columnWidth: 'small' },
  { label: 'TIN', value: 'tin', columnWidth: 'small' },
  {
    label: 'Business Website',
    value: 'business_website',
    columnWidth: 'small',
  },
  { label: 'Location', value: 'state', columnWidth: 'small' },
  { label: 'Date Registered', value: 'createdAt', columnWidth: 'small' },
  { label: 'Email Address', value: 'email', columnWidth: 'large' },
]

export const ADDRESSTABLE = [
  { label: 'Main balance', value: 'balance', columnWidth: 'small' },
  { label: 'Commission', value: 'commission', columnWidth: 'small' },
  { label: 'Business Address', value: 'address', columnWidth: 'small' },
  { label: 'noVisibility', value: 'empty', columnWidth: 'small' },
  { label: 'noVisibility', value: 'empty', columnWidth: 'small' },
  { label: 'noVisibility', value: 'empty', columnWidth: 'large' },
]

export const OWNERTABLE = [
  { label: 'Name', value: 'ownerName', columnWidth: 'small' },
  { label: 'Phone Number', value: 'ownerPhone', columnWidth: 'small' },
  { label: 'KYC Level', value: 'kyc', columnWidth: 'small' },
  { label: 'BVN', value: 'bvn', columnWidth: 'small' },
  { label: 'Date Registered', value: 'createdAt', columnWidth: 'small' },
  { label: 'Email Address', value: 'ownerEmail', columnWidth: 'large' },
]
export const DIRECTORS_INFOMATION = [
  { label: 'Director’s Name', value: 'directors_name', columnWidth: 'large' },
  { label: 'Email Address', value: 'ownerPhone', columnWidth: 'small' },
  { label: 'Phone Number', value: 'kyc', columnWidth: 'small' },
  { label: 'Designation', value: 'bvn', columnWidth: 'small' },
  { label: 'BVN', value: 'bvn', columnWidth: 'small' },
  { label: 'NIN', value: 'NIN', columnWidth: 'small' },
  { label: 'PEP Status', value: 'createdAt', columnWidth: 'small' },
  { label: 'Valid ID', value: 'ownerEmail', columnWidth: 'large' },
  { label: 'Selfie', value: 'ownerEmail', columnWidth: 'large' },
]
export const CONTACT_INFOMATION = [
  { label: 'Contact Name', value: 'directors_name', columnWidth: 'small' },
  { label: 'Email Address', value: 'ownerPhone', columnWidth: 'small' },
  { label: 'Phone Number', value: 'kyc', columnWidth: 'small' },
  { label: 'BVN', value: 'bvn', columnWidth: 'small' },
]
export const PASSCODETABLE = [
  { label: 'Transaction PIN', value: 'pinStatus', columnWidth: 'small' },
  { label: 'Gender', value: 'gender', columnWidth: 'small' },
  { label: 'Passcode', value: 'passcodeStatus', columnWidth: 'small' },
  { label: 'noVisibility', value: 'empty', columnWidth: 'small' },
  { label: 'noVisibility', value: 'empty', columnWidth: 'small' },
  { label: 'noVisibility', value: 'empty', columnWidth: 'large' },
]
export const BVNTABLE = [
  { label: 'First Name', value: 'firstName', columnWidth: 'small' },
  { label: 'Surname', value: 'surname', columnWidth: 'small' },
  { label: 'Middle Name', value: 'middleName', columnWidth: 'small' },
  { label: 'Phone Number', value: 'phoneNumber', columnWidth: 'small' },
]

export const businessDetailsHelper = (data: any) => {
  let commision = data?.wallet_details?.find(
    (wallet: { [key: string]: any }) => wallet?.wallet_type === 'commission'
  )
  let main = data?.wallet_details?.find(
    (wallet: { [key: string]: any }) => wallet?.wallet_type === 'main'
  )

  return [
    {
      spacing: false,
      button: false,
      header: DETAILSTABLE,
      data: {
        id: data?.id,
        name: data?.name,
        tin: 1234,
        phone_number: data?.phone_number,
        state: data?.state,
        createdAt: data?.created_at
          ? formatDate(data?.created_at, 'YYYY-MM-DD HH:mm:ss')
          : '',

        email: data?.email,
      },
    },

    {
      title: 'Directors Information',
      spacing: false,
      button: false,
      header: DIRECTORS_INFOMATION,
      data: {
        ownerName:
          `${data?.business_owner?.first_name} ${data?.business_owner?.last_name}` ||
          '',
        ownerPhone: data?.business_owner?.phone_number,
        kyc: data?.kyc_level.toString(),
        bvn: data?.bvn,
        createdAt: data?.business_owner?.created_at
          ? formatDate(data?.created_at, 'YYYY-MM-DD HH:mm:ss')
          : '',

        email: data?.business_owner?.email,
      },
    },
    {
      spacing: false,
      button: false,
      header: ADDRESSTABLE,
      data: {
        address: data?.address,
        balance: amountHelper(main?.wallet_balance),
        commission: amountHelper(commision?.wallet_balance),
        empty: '',
      },
    },
    {
      title: 'Business Owner Details',
      spacing: false,
      button: false,
      header: OWNERTABLE,
      data: {
        ownerName:
          `${data?.business_owner?.first_name} ${data?.business_owner?.last_name}` ||
          '',
        ownerPhone: data?.business_owner?.phone_number,
        kyc: data?.kyc_level?.toString(),
        bvn: data?.bvn,
        createdAt: data?.business_owner?.created_at
          ? formatDate(data?.created_at, 'YYYY-MM-DD HH:mm:ss')
          : '',

        email: data?.business_owner?.email,
      },
    },
    {
      spacing: false,
      button: false,
      header: PASSCODETABLE,
      data: {
        pinStatus: data?.business_owner.transaction_pin ? 'Set' : 'Not Set',
        gender: data?.business_owner?.gender,
        passcodeStatus: data?.business_owner.passcode ? 'Set' : 'Not Set',
        empty: '',
      },
    },
    {
      title: 'Contact Information',
      spacing: false,
      button: false,
      header: CONTACT_INFOMATION,
      data: {
        firstName: data?.bvn_verification?.first_name || 'N/A',
        surname: data?.bvn_verification?.last_name || 'N/A',
        middleName: data?.bvn_verification?.middle_name || 'N/A',
        phoneNumber: data?.bvn_verification?.phone_number || 'N/A',
      },
    },
  ]
}

export const actionOptions = [
  {
    label: 'Reset Security Question',
    value: 'Reset Security Question',
  },
  {
    label: 'Edit Business Details',
    value: 'Edit Business Details',
  },
  {
    label: 'Edit Business Owner Details',
    value: 'Edit Business Owner Details',
  },
].filter(Boolean)
interface IDirector {
  id: string
  business_id: string
  first_name: string
  last_name: string
  designation: string
  bvn: string
  nin: string
  email: string
  phone_number: string
  pep: boolean
  id_type: string
  id_number: string
  gender: string
  dob: string
  selfie_status: string
  selfie_photo: string
  id_status: string
  id_document: string
  created_at: string
  updated_at: string
}

interface ITableHeader {
  label: string
  value: string
  columnWidth: 'small' | 'large'
}

interface ITransformedData {
  director_name: string
  email: string
  phone_number: string
  designation: string
  bvn: string
  nin: string
  dob: string
  pep: string
  id_document: string
  selfie_photo: string
}

interface ITransformedObject {
  spacing: boolean
  button: boolean
  header: ITableHeader[]
  data: ITransformedData[]
  title: string
}

export const transformDirectorData = (
  inputData: IDirector[]
): ITransformedObject => {
  // Return empty object if input is empty
  if (!inputData || inputData.length === 0) {
    return {
      spacing: false,
      button: false,
      header: [],
      data: [],
      title: "Director's Information",
    }
  }

  // Format the date string
  const formatDate = (dateString: string): string => {
    const date = new Date(dateString)
    return date.toLocaleDateString('en-GB') // formats to DD/MM/YYYY
  }

  const headers: ITableHeader[] = [
    {
      label: "Director's Name",
      value: 'director_name',
      columnWidth: 'small',
    },
    {
      label: 'Email Address',
      value: 'email',
      columnWidth: 'small',
    },
    {
      label: 'Phone Number',
      value: 'phone_number',
      columnWidth: 'small',
    },
    {
      label: 'Designation',
      value: 'designation',
      columnWidth: 'small',
    },
    {
      label: 'BVN',
      value: 'bvn',
      columnWidth: 'small',
    },
    {
      label: 'NIN',
      value: 'nin',
      columnWidth: 'small',
    },
    {
      label: 'DOB',
      value: 'dob',
      columnWidth: 'small',
    },
    {
      label: 'PEP Status',
      value: 'pep',
      columnWidth: 'small',
    },
    {
      label: 'Valid ID',
      value: 'id_document',
      columnWidth: 'large',
    },
    {
      label: 'Selfie',
      value: 'selfie_photo',
      columnWidth: 'large',
    },
  ]

  // Transform each director's data
  const transformedData: ITransformedData[] = inputData.map((director) => ({
    director_name: `${director.first_name} ${director.last_name}`,
    email: director.email,
    phone_number: director.phone_number,
    designation: director.designation,
    bvn: director.bvn,
    nin: director.nin,
    dob: formatDate(director.dob),
    pep: director.pep ? 'Yes' : 'No',
    id_document: director.id_document,
    selfie_photo: director.selfie_photo,
  }))

  const transformedObject: ITransformedObject = {
    spacing: false,
    button: false,
    header: headers,
    data: transformedData,
    title: "Director's Information",
  }

  return transformedObject
}
